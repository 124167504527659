import BaseMixin from '@/views/components/officials/mixins/Base';
import OfficialsPublic from '@/services/Api/Public/Officials';

export default {
  mixins: [BaseMixin],
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    this.fetchAffiliates();
  },
  methods: {
    async fetchAffiliates() {
      this.isLoading = true;
      const data = await OfficialsPublic.affiliates(
        this.officialsCategory,
        { triennium: this.triennium },
      );
      this.setAffiliates(data.data.data);
      this.isLoading = false;
    },
  },
};
