<template>
  <div class="p-4">
    <h3 class="mt-4">
      {{ category.title }}
    </h3>

    <b-alert variant="warning" :show="hasIfacRestricted">
      You may login to see the IFAC Restricted names
    </b-alert>

    <b-alert class="mb-0" variant="warning" :show="!isLoading && rowData.length === 0">
      No public IFAC official in this category at this time
    </b-alert>

    <ag-grid-vue
      v-show="rowData.length > 0"
      class="ag-theme-alpine"
      :pagination="true"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :gridOptions="gridOptions"
      domLayout="autoHeight"
      :paginationPageSize="paginationPageSize"
      :getRowStyle="getRowStyle"
    ></ag-grid-vue>
  </div>
</template>

<script>
import Base from '@/views/components/officials/mixins/Base';
import Affiliates from '@/views/components/officials/mixins/Affiliates';

export default {
  mixins: [Base, Affiliates],
  computed: {
    hasIfacRestricted() {
      return this.rowData.filter((a) => {
        if (a.head || a.ifac) {
          if (a.head) {
            return a.head.is_name_visible === false;
          }

          if (a.ifac) {
            return a.ifac.is_name_visible === false;
          }

          return true;
        }

        return a.is_name_visible === false;
      }).length > 0;
    },
  },
};
</script>
